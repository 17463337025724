import { graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../static/forms/event.json"
import ContactDetails from "../components/ContactDetails/ContactDetails"
import EventAgenda from "../components/EventAgenda/EventAgenda"
import EventBanner from "../components/EventBanner/EventBanner"
import EventQuote from "../components/EventQuote/EventQuote"
import EventSpeakers from "../components/EventSpeakers/EventSpeakers"
import EventWithIcon from "../components/EventWithIcon/EventWithIcon"
import IconEvent from "../components/IconEvent/IconEvent"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import SectionViewerComponent from "../components/SectionViewerComponent/SectionViewerComponent"
import TwoColBlock from "../components/TwoColBlock/TwoColBlock"
import DefaultForm from "../components/forms/default-form-layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { removeDataAttributes } from "../components/Common/utils"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
const { GetUrlSet } = require("@starberry/gatsby-theme-utils/Common/GGFXClient/Client") 


const EventTemplate = ({ data, pageContext }, props) => {
  const [pageData, setPageData] = useState(data?.strapiEvent)
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)
  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string

  const PageData = pageData

  const [show, setShow] = useState(false)
  const [idArray, setIdArray] = useState([])

  const whatsappData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      if (sessionStorage.getItem("eventform") !== "true") {
        setShow(true)
      }
      sessionStorage.setItem("eventform", "true")
    }, 20000)
    return () => clearTimeout(timer)
  }, [])

  const formData = PageData?.modules?.filter(
    data => data.__typename === "STRAPI__COMPONENT_COMPONENTS_FORM_CONTENT"
  )
  const form_data = formData?.length > 0 ? formData : ""

  const formContent = form_data?.length > 0 ? form_data[0] : ""

  const userContent = formContent?.user_content?.data?.user_content

  const { isLargeScreen } = useDeviceMedia()

  const pageurl = typeof window !== "undefined" ? window.location.href : ""


  useEffect(() => {
    setTimeout(() => {
      const nodeList =
        typeof document !== "undefined"
          ? document.querySelectorAll("section")
          : ""
      const array = [...nodeList]
      const filteredArray = array.filter(element => element.hasAttribute("id"))
      const result = filteredArray.map(element => element.getAttribute("id"))
      setIdArray(result)
    }, 1000)
  }, [])

  const getContactModule=PageData?.modules?.find(item=>item.__typename ==="STRAPI__COMPONENT_PAGE_MODULES_CONTACT_MODULE"
  || item?.__component === "page-modules.contact-module"
  )

  const query = qs.stringify(
    {
      populate: {
        banner:{
          populate:{
            image:"*",
            cta_1_link:"*",
            cta_2_link:"*",
            cta_3_link:"*"
          }
        },  
        text_item:"*",
        modules: {
          on: {
            'page-modules.icon-content': {
              populate: {
                icon_module: {
                  populate:{
                  image:"*",

                }
              }
              },
            },
            'shared.seo':{
              populate: {
                metaTitle: "*"
              }
            },
            'page-modules.image-content': {
              populate: {
                left_right_block: {
                  populate:{
                    image:"*",
                    menu:{
                      populate:{
                        parent:"*"
                      }
                    }
                  }
                }
              },
            },
            'page-modules.contact-module':{
              populate: {
                contact_module: "*"
              }
            },
            'page-modules.quote-slider':{
              populate: {
                quote_item: "*"
              },
            },
            'page-modules.contact-module':{
              populate:{
                contact_module: "*"
              }
            },
            'page-modules.event-agenda':{
              populate: {
                add_time: "*"
              }
            },
            'components.location-component': {
              populate: {
                location_module: {
                  populate:{
                    image:"*",
                    menu:{
                      populate:{
                        parent:"*"
                      }
                    }
                  }
                }
              },
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/events/${urlParams.get('strapi_id')}?${query}`
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;
    
    if(isPreview){
      setIsPreviewEnabled(true)

      const fetchData = async () => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });
          const getData = await response.json();
          const previewData=removeDataAttributes(getData)
          setLoading(false)
          setEnablePreview(false)
          let myPrevData;
          if(pageData){
            myPrevData = pageData
          } else {
            myPrevData = {};
            myPrevData.banner_image = {}
            myPrevData.modules=[]
            myPrevData.id = urlParams.get('strapi_id')
          }
          if(previewData){
            myPrevData.banner.image = previewData?.banner?.image
            myPrevData.banner.banner_content = previewData?.banner.banner_content
            myPrevData.banner.banner_title = previewData?.banner.banner_title
            myPrevData.banner.cta_1_title = previewData.banner.cta_1_title
            myPrevData.banner.cta_1_custom_link = previewData.banner.cta_1_custom_link
            myPrevData.banner.cta_1_link = previewData.banner.cta_1_link
            myPrevData.banner.cta_2_link = previewData.banner.cta_2_link
            myPrevData.banner.cta_2_title = previewData.banner.cta_2_title
            myPrevData.banner.cta_2_custom_link = previewData.banner.cta_2_custom_link
            myPrevData.banner.cta_3_custom_link = previewData.banner.cta_3_custom_link
            myPrevData.banner.cta_3_title = previewData.banner.cta_3_title
            myPrevData.imagetransforms = previewData?.imagetransforms
            myPrevData.title = previewData?.title
            myPrevData.whatsapp_no = myPrevData.whatsapp_no
            myPrevData.whatsapp_text = myPrevData.whatsapp_text
            myPrevData.slug = previewData?.slug
            myPrevData.thankyou_content = previewData?.thankyou_content
            myPrevData.address = previewData?.address
            myPrevData.modules=previewData?.modules
            myPrevData.text_item = previewData?.text_item

            setPageData(pageData => ({ ...pageData, myPrevData }));
            if(previewData?.modules?.length>0){
              const seoModule = previewData?.modules?.find(module=>module.__component === "shared.seo")
              sessionStorage.setItem("previewMeta",JSON.stringify(seoModule))

            }
          }
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
  
        };
      fetchData();
      }
      else{
        setLoading(false)
        setIsPreviewEnabled(false)
        sessionStorage.removeItem("previewMeta")
      }

  },[])


    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }

    if(enablePreview){
      return null
    }

  return (
    <Layout isEvent={true}>
      <SecondaryHeader
        idArray={idArray}
        isEvent
        whatsapp_num={PageData?.whatsapp_no}
        whatsapp_text={PageData?.whatsapp_text}
      />

      {/* <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title}>
      </SEO> */}
      <EventBanner
        handleModal={handleModal}
        title={PageData?.title}
        {...PageData?.banner}
        id={PageData?.strapi_id}
        isPreviewEnabled={isPreviewEnabled}
        imagetransforms={
          PageData.imagetransforms?.banner_section_banner_image_Transforms
        }
      />
      {isLargeScreen && (
        <SectionViewerComponent
          idArray={idArray}
          isEvent
          whatsapp_num={PageData?.whatsapp_no}
        />
      )}
      {PageData?.modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT" || 
              item?.__component==="page-modules.icon-content") &&
              (item?.layout === "event_1" ? (
                <IconEvent isPreviewEnabled={isPreviewEnabled} {...item} />
              ) : item?.layout === "event_2" ? (
                <EventWithIcon
                  isPreviewEnabled={isPreviewEnabled}
                  id={PageData.strapi_id}
                  imagetransforms={
                    PageData.imagetransforms?.icon_section_image_Transforms
                  }
                  {...item}
                />
              ) : item?.layout === "event_speakers" ? (
                <EventSpeakers
                  isPreviewEnabled={isPreviewEnabled}
                  id={PageData.strapi_id}
                  ggfx_results={PageData?.ggfx_results}
                  imagetransforms={
                    PageData.imagetransforms?.icon_section_image_Transforms
                  }
                  {...item}
                />
              ) : null)}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" ||
              item?.__component === "page-modules.image-content") && (
              <TwoColBlock
                isPreviewEnabled={isPreviewEnabled}
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                {...item}
              />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_QUOTE_SLIDER" ||
              item?.__component === "page-modules.quote-slider") && (
              <EventQuote isPreviewEnabled={isPreviewEnabled} {...item} />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_COMPONENTS_LOCATION_COMPONENT"  || 
              item?.__component === "components.location-component") && (
              <TwoColBlock
                id={PageData.strapi_id}
                imagetransforms={PageData.imagetransforms}
                ggfx_results={PageData.ggfx_results}
                left_right_block={
                  item.location_module ? [item.location_module] : ""
                }
                locationblock={true}
                isPreviewEnabled={isPreviewEnabled}


              />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_EVENT_AGENDA" ||
              item?.__component === "page-modules.event-agenda") && (
              <EventAgenda isPreviewEnabled={isPreviewEnabled} {...item} />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CONTACT_MODULE" ||
              item?.__component === "page-modules.contact-module") && (
              <>
                <ContactDetails
                  data={{
                    contact_info: item,
                    address: PageData?.address,
                    form_data: form_data,
                    title: PageData?.title,
                    thankyou_image:PageData?.thankyou_image,
                    thankyou_content:PageData?.thankyou_content
                  }}
                  whatsappData={whatsappData}
                  whatsapp_text={PageData?.whatsapp_text}
                  isPreviewEnabled={isPreviewEnabled}
                  eventTime = {PageData?.text_item}
                />
              </>
            )}
          </div>
        )
      })}
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <DefaultForm
                fields={FormFields}
                formTitle={getContactModule?.title?getContactModule?.title:"Register your interest"}
                userContent={userContent}
                to_email_id={formContent?.admin_mail}
                emailSubUser={formContent?.email_subject_user}
                emailSubAdmin={formContent?.email_subject_admin}
                selectType="events"
                thankingAddress={PageData?.address}
                pageTitle={PageData?.title}
                form_id_value="event-banner-id"
                phone_id_value="desktop-id-event"
                thankyou_image={PageData?.thankyou_image}
                thankyou_content={PageData?.thankyou_content}
                sourceUrl={pageurl}
                eventTime = {PageData?.text_item}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )

}

export const Head = ({ data }) => {
  const eventData = data?.strapiEvent
  const seoComponent = eventData?.modules?.find(item=>item.__typename === "STRAPI__COMPONENT_SHARED_SEO")
  let myimgtransforms = ""
  if (eventData?.imagetransforms?.banner_section_banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      eventData?.imagetransforms?.banner_section_banner_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (eventData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms = JSON.parse(
      eventData?.imagetransforms?.metaog_Transforms
    )
  }

  let getImg= eventData?.banner?.image?.url? eventData?.banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  let imageUrl=""
  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }
  else if(myimgtransforms){
    // ogRender()
  const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  imageUrl=ProcessedResult?ProcessedResult["744x838"]:eventData?.banner?.image?.url
  }

  //new ggfx image
  const getImage =
  eventData?.banner?.image?.url
      ? eventData?.banner?.image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && eventData?.ggfx_results?.length > 0
      ? eventData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  // filter image with image size
  const getOGImg =
    filterImage?.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: eventData.strapi_id,
      field: "metaog",
      contentType: "event"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl

  const metaTitle = seoComponent?.metaTitle ? seoComponent?.metaTitle : `${eventData?.title}`
  const metaDesc = seoComponent?.metaDescription ? seoComponent?.metaDescription : `Know more about ${eventData?.title} here.`

 //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


 useEffect(() => {
  const timer = setTimeout(() => {
    if (typeof window !== "undefined" && isPreview) {
      try {
        const sessionMetaData = sessionStorage.getItem("previewMeta");
        if (sessionMetaData) {
          const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, [isPreview]);

  useEffect(() => {
    if(isPreview){
      document.title =  `${preMetaTitle} | haus & haus`;
    }
  }, [preMetaTitle]);

  return <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} description={metaDesc} imageUrl={pageImage}></SEO>
}

export default EventTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiEvent(strapi_id: { eq: $page_id }) {
      ...EventFragment
      modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT {
          __typename
          ...IconFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          ...ImageContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_QUOTE_SLIDER {
          __typename
          ...QuoteFragment
        }
        ... on STRAPI__COMPONENT_SHARED_SEO {
          __typename
          metaTitle
          metaDescription
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_EVENT_AGENDA {
          __typename
          ...AgendaFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CONTACT_MODULE {
          __typename
          ...EvantContactFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_LOCATION_COMPONENT {
          __typename
          id
          location_module {
            cta_label
            latitude
            longitude
            image {
              url
            }
            layout
            menu {
              slug
            }
            description {
              data {
                description
              }
            }
            title
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_FORM_CONTENT {
          __typename
          id
          email_subject_admin
          email_subject_user
          user_content {
            data {
              user_content
            }
          }
          admin_mail
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          whatsapp_message
        }
      }
    }
  }
`
